import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import CaseStudiesDetailsContent from '../components/CaseStudiesDetails/CaseStudiesDetailsContent'
import RelatedProjects from '../components/CaseStudiesDetails/RelatedProjects'
import banner_img from '../assets/images/projects/project5.jpg'

const facts = [
    {
        id: 0,
        className: 'postgresql',
        label: 'Sub-Sharan Company',
    },
    {
        id: 1,
        className: 'elasticsearch',
        label: 'Garage & Forecourt Management',
    },
    {
        id: 2,
        className: 'opensearch',
        label: 'Fuel Handling',
    },
    {
        id: 3,
        className: 'opensearch',
        label: 'Data-Driven Insight',
    }

];

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Case Studies Details" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Case Studies Details" 
            />
            <CaseStudiesDetailsContent
                banner_img={banner_img}
                facts={facts}
                client={"Garage & Forecourt"}
                location={"1 Sandton Drive Sandhurst,2196 Gauteng"}
                technologies={"Javascript"}
                completed={"May 2023"}
                website={"https://www.garageforecourt.com"}
                type={"Garage & Fuel Handling equipment specialists."}
                title={"Garage&Forecourt"}
                description={"JB Garage & Forecourt specialises in providing Fuel Handling and Management solutions for the Sub-Saharan petroleum industry."}
                important_facts={""}
                long_description1={"Today the product range that we offer can be divided into Fuel Handling equipment,Oil Handling equipment and Garage Equipment with us being suppliers of Wayne,Prowalco and Tokheim pumps. We also service Gilbarco range of pumps."}
                long_description2={"The Fusion forecourt system is a single system that gives you many layers of control."}
                results={"theParse is currently working with the Garage&Forecourt team to develop a communication platform for clients in the Sub-Saharan petroleum industry."}
            />
            {/* <RelatedProjects /> */}
            <Footer />
        </Layout>
    );
}

export default CaseDetails